import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.classList.remove('translate-y-full');
    this.element.classList.add('opacity-100');

    setTimeout(this.hide.bind(this), 3500);
  }

  hide() {
    this.element.classList.add('translate-y-full');
    this.element.classList.remove('opacity-100');

    this.element.addEventListener('transitionend', this.cleanup.bind(this), { once: true, passive: true });
  }

  cleanup() {
    this.element.remove();
  }
}
